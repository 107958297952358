import React, { createContext, useState, useContext, useEffect } from 'react';
import { db } from '../firebase';
import { collection, onSnapshot, addDoc, deleteDoc, updateDoc, doc, writeBatch, getDocs } from 'firebase/firestore';
import { useAuth } from './AuthContext';

const TeamContext = createContext();

export function useTeam() {
  return useContext(TeamContext);
}

export function TeamProvider({ children }) {
  const [teamMembers, setTeamMembers] = useState([]);
  const { user, loading: authLoading } = useAuth();

  useEffect(() => {
    if (authLoading) return;
    if (!user) {
      setTeamMembers([]);
      return;
    }

    const teamMembersRef = collection(db, 'teamMembers');
    const unsubscribe = onSnapshot(teamMembersRef, (snapshot) => {
      const membersArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        eventPreferences: {
          IBR1: false,
          IBR2: false,
          IBR3: false,
          IBR4: false,
          NOW: false,
          WBI: false,
          Wedding: false,
          ...doc.data().eventPreferences
        },
        unavailableDates: doc.data().unavailableDates || {},
        active: doc.data().active !== undefined ? doc.data().active : true
      }));
      setTeamMembers(membersArray);
    }, (error) => {
      console.error("Error fetching team members:", error);
    });

    return () => unsubscribe();
  }, [user, authLoading]);

  const addTeamMember = async (member) => {
    try {
      const teamMembersRef = collection(db, 'teamMembers');
      await addDoc(teamMembersRef, {
        ...member,
        eventPreferences: {
          IBR1: false,
          IBR2: false,
          IBR3: false,
          IBR4: false,
          NOW: false,
          WBI: false,
          Wedding: false,
          ...member.eventPreferences
        },
        active: true
      });
    } catch (error) {
      console.error("Error adding team member:", error);
    }
  };

  const deleteTeamMember = async (id) => {
    try {
      const memberRef = doc(db, 'teamMembers', id);
      await deleteDoc(memberRef);
    } catch (error) {
      console.error("Error deleting team member:", error);
    }
  };

  const updateTeamMember = async (id, updatedMember) => {
    try {
      const memberRef = doc(db, 'teamMembers', id);
      await updateDoc(memberRef, {
        ...updatedMember,
        eventPreferences: {
          IBR1: false,
          IBR2: false,
          IBR3: false,
          IBR4: false,
          NOW: false,
          WBI: false,
          Wedding: false,
          ...updatedMember.eventPreferences
        }
      });
    } catch (error) {
      console.error("Error updating team member:", error);
    }
  };

  const removeAllUnavailableEvents = async () => {
    console.log("removeAllUnavailableEvents called");
    try {
      const batch = writeBatch(db);
      const teamMembersRef = collection(db, 'teamMembers');
      const snapshot = await getDocs(teamMembersRef);
      
      snapshot.docs.forEach((doc) => {
        const memberRef = doc.ref;
        batch.update(memberRef, { unavailableDates: {} });
      });

      await batch.commit();

      // Update local state immediately after successful batch write
      setTeamMembers(prevMembers => prevMembers.map(member => ({
        ...member,
        unavailableDates: {}
      })));

      console.log("All unavailable events removed successfully");
    } catch (error) {
      console.error("Error removing all unavailable events:", error);
    }
  };

  const saveRota = async (rota, rotaName, userEmail) => {
    try {
      console.log("Attempting to save rota:", rota);
      const rotasRef = collection(db, 'rotas');
      const newRotaRef = await addDoc(rotasRef, {
        name: rotaName,
        createdAt: new Date().toISOString(),
        rota: rota,
        createdBy: userEmail
      });
      console.log("Rota saved successfully with ID:", newRotaRef.id);
      return newRotaRef.id;
    } catch (error) {
      console.error("Error saving rota:", error);
      console.error("Error details:", error.message, error.code);
      throw error;
    }
  };

  return (
    <TeamContext.Provider value={{ 
      teamMembers, 
      addTeamMember, 
      deleteTeamMember, 
      updateTeamMember, 
      saveRota,
      removeAllUnavailableEvents
    }}>
      {children}
    </TeamContext.Provider>
  );
}