import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './HomePage.css';

function HomePage() {
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    const fetchLogoUrl = async () => {
      const storage = getStorage();
      const logoRef = ref(storage, 'logos/bethanylogo.png');
      try {
        const url = await getDownloadURL(logoRef);
        setLogoUrl(url);
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogoUrl();
  }, []);

  return (
    <div className="home-page">
      <div className="content-container">
        <h1>BETHANY SOLO MULTIMEDIA TEAM</h1>
        <p className="welcome-text">Welcome to the Multimedia Team Management App</p>
        <div className="logo-container">
          {logoUrl && <img src={logoUrl} alt="Bethany Logo" className="logo" />}
        </div>
      </div>
    </div>
  );
}

export default HomePage;