import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import TeamMembersPage from './components/TeamMembersPage';
import AddMemberPage from './components/AddMemberPage';
import EditMemberPage from './components/EditMemberPage';
import CreateRotaPage from './components/CreateRotaPage';
import GeneratedRotaPage from './components/GeneratedRotaPage';
import SchedulesPage from './components/SchedulesPage';
import ViewRotaPage from './components/ViewRotaPage';
import LoginPage from './components/LoginPage';
import UnauthorizedPage from './components/UnauthorizedPage';
import { TeamProvider } from './context/TeamContext';
import { AuthProvider, useAuth } from './context/AuthContext';
import './App.css';


function PrivateRoute({ children }) {
  const { user, loading, isAuthorized } = useAuth();
  
  if (loading) {
    return <div>Loading...</div>;
  }
  
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!isAuthorized) {
    return <Navigate to="/unauthorized" />;
  }
  
  return children;
}

function AppContent() {
  const { loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          <Route path="/" element={<PrivateRoute><HomePage /></PrivateRoute>} />
          <Route path="/team-members" element={<PrivateRoute><TeamMembersPage /></PrivateRoute>} />
          <Route path="/add-member" element={<PrivateRoute><AddMemberPage /></PrivateRoute>} />
          <Route path="/edit-member/:id" element={<PrivateRoute><EditMemberPage /></PrivateRoute>} />
          <Route path="/create-rota" element={<PrivateRoute><CreateRotaPage /></PrivateRoute>} />
          <Route path="/generated-rota" element={<PrivateRoute><GeneratedRotaPage /></PrivateRoute>} />
          <Route path="/schedules" element={<PrivateRoute><SchedulesPage /></PrivateRoute>} />
          <Route path="/view-rota/:id" element={<PrivateRoute><ViewRotaPage /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        </div>
      </div>
    </Router>
  );
}

function App() {
  return (
    <AuthProvider>
      <TeamProvider>
        <AppContent />
      </TeamProvider>
    </AuthProvider>
  );
}

export default App;