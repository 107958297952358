import React from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './Navbar.css';

function Navbar() {
  const { user, logout, isAuthorized } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  if (!user) {
    return null; // Don't show navbar if user is not logged in
  }

  return (
    <nav className="navbar">
      <div className="navbar-container">
        {isAuthorized && (
          <div className="navbar-links">
            <NavLink to="/" end className={({ isActive }) => isActive ? 'active' : ''}>
              Home
            </NavLink>
            <NavLink to="/team-members" className={({ isActive }) => isActive ? 'active' : ''}>
              Team Members
            </NavLink>
            <NavLink to="/create-rota" className={({ isActive }) => isActive ? 'active' : ''}>
              Create Rota
            </NavLink>
            <NavLink to="/schedules" className={({ isActive }) => isActive ? 'active' : ''}>
              Schedules
            </NavLink>
          </div>
        )}
        <div className="user-info">
          <span className="user-email">{user.email}</span>
          <button onClick={handleLogout} className="logout-button">Logout</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;