import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './UnauthorizedPage.css';

function UnauthorizedPage() {
  const { user, isAuthorized } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthorized) {
      navigate('/');
    }
  }, [isAuthorized, navigate]);

  if (isAuthorized) {
    return null;
  }

  return (
    <div className="unauthorized-page">
      <div className="content-container">
        <h1>BETHANY SOLO MULTIMEDIA TEAM</h1>
        <div className="message-container">
          <h2>Unauthorized Access</h2>
          <p>Your account ({user?.email}) is not authorized. Please contact david@bethanysolo.or.id for authorization.</p>
        </div>
      </div>
    </div>
  );
}

export default UnauthorizedPage;