import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useTeam } from '../context/TeamContext';
import "react-datepicker/dist/react-datepicker.css";
import './CreateRotaPage.css';

function CreateRotaPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { teamMembers } = useTeam();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedDates, setSelectedDates] = useState([]);
  const [memberScarcityFactors, setMemberScarcityFactors] = useState({});
  const [selectedAlgorithm, setSelectedAlgorithm] = useState('weighted');

  const positions = ['Jimmy Jib', 'Selatan', 'Utara', 'Switcher', 'Title 1', 'Title 2', 'Animasi', 'Foto'];
  const qualificationMap = {
    'Jimmy Jib': 'JimmyJib',
    'Selatan': 'Moving',
    'Utara': 'Moving',
    'Switcher': 'Switcher',
    'Title 1': 'Title',
    'Title 2': 'Title',
    'Animasi': 'Animasi',
    'Foto': 'Foto'
  };
  const eventTypes = ['IBR1', 'IBR2', 'IBR3', 'IBR4', 'NOW', 'WBI', 'Wedding'];
  
  useEffect(() => {
    if (location.state?.selectedDates) {
      const parsedDates = location.state.selectedDates.map(dateString => {
        return new Date(dateString);
      });
      setSelectedDates(parsedDates);
    }
    if (location.state?.selectedAlgorithm) {
      setSelectedAlgorithm(location.state.selectedAlgorithm);
    }
  }, [location.state]);

  useEffect(() => {
    const factors = {};
    teamMembers.forEach(member => {
      const preferredEventCount = eventTypes.filter(event => member.eventPreferences[event]).length;
      factors[member.id] = preferredEventCount > 0 ? 1 / preferredEventCount : 0;
    });
    setMemberScarcityFactors(factors);
  }, [teamMembers]);

  useEffect(() => {
    if (location.state?.isRegenerating) {
      generateRota();
    }
  }, [location.state?.isRegenerating]);

  const addDate = () => {
    if (!selectedDates.some(date => date.toDateString() === selectedDate.toDateString())) {
      setSelectedDates(prevDates => [...prevDates, selectedDate]);
    }
  };

  const removeDate = (dateToRemove) => {
    setSelectedDates(prevDates => prevDates.filter(date => date.toDateString() !== dateToRemove.toDateString()));
  };

  function generateRota() {
    if (selectedDates.length === 0) {
      console.error("No dates selected for rota generation");
      return;
    }

    const rota = {};
    const assignmentCounts = {};
    let davidSundayCount = 0;

    teamMembers.forEach(member => {
      assignmentCounts[member.id] = {};
    });

    selectedDates.forEach(date => {
      const dayOfWeek = date.getDay();
      let events;
      switch (dayOfWeek) {
        case 0: events = ['IBR1', 'IBR2', 'IBR3', 'IBR4']; break;
        case 3: events = ['WBI']; break;
        case 5: events = ['NOW']; break;
        case 6: events = ['Wedding']; break;
        default: events = [''];
      }
      events.forEach(event => {
        const eventKey = `${formatDateKey(date)} ${event}`;
        const isSunday = dayOfWeek === 0;
        rota[eventKey] = assignPositions(date, event, assignmentCounts, isSunday, davidSundayCount);
        
        if (isSunday && Object.values(rota[eventKey]).includes('David')) {
          davidSundayCount++;
        }
      });
    });

    navigate('/generated-rota', { 
      state: { 
        generatedRota: rota, 
        selectedDates: selectedDates.map(date => date.toISOString()),
        selectedAlgorithm: selectedAlgorithm
      },
      replace: true
    });
  }

  function assignPositions(date, event, assignmentCounts, isSunday, davidSundayCount) {
    const assignments = {};
    const dateString = formatDateKey(date);
    let availableMembers = teamMembers.filter(member => member.active);

    // Sort positions based on the number of qualified members
    const sortedPositions = getSortedPositions(availableMembers, event, dateString);

    sortedPositions.forEach(position => {
      const qualification = qualificationMap[position];
      const qualifiedMembers = availableMembers.filter(member => 
        member.qualifications[qualification] &&
        member.eventPreferences[event] &&
        !isUnavailable(member, dateString, event)
      );

      if (qualifiedMembers.length > 0) {
        const assigned = selectedAlgorithm === 'basic' 
          ? selectMemberWithBasicRandom(qualifiedMembers)
          : selectMemberWithWeightedRandom(qualifiedMembers, assignmentCounts, position, isSunday, davidSundayCount, dateString);
        assignments[position] = assigned.name;
        
        // Update assignment counts
        if (!assignmentCounts[assigned.id][dateString]) {
          assignmentCounts[assigned.id][dateString] = 0;
        }
        assignmentCounts[assigned.id][dateString]++;
        
        availableMembers = availableMembers.filter(member => member.id !== assigned.id);
      } else {
        assignments[position] = 'Unassigned';
      }
    });

    return assignments;
  }

  function getSortedPositions(availableMembers, event, dateString) {
    const positionCounts = positions.map(position => {
      const qualification = qualificationMap[position];
      const qualifiedCount = availableMembers.filter(member => 
        member.qualifications[qualification] &&
        member.eventPreferences[event] &&
        !isUnavailable(member, dateString, event)
      ).length;
      return { position, qualifiedCount };
    });

    return positionCounts
      .sort((a, b) => a.qualifiedCount - b.qualifiedCount)
      .map(item => item.position);
  }

  function selectMemberWithBasicRandom(members) {
    return members[Math.floor(Math.random() * members.length)];
  }

  function selectMemberWithWeightedRandom(members, assignmentCounts, position, isSunday, davidSundayCount, dateString) {
    const weights = members.map(member => {
      let weight = (1 / (getTotalAssignments(assignmentCounts[member.id]) + 1)) * memberScarcityFactors[member.id];
      
      // Reduce weight if member already has 2 or more assignments on this date
      const dateAssignments = assignmentCounts[member.id][dateString] || 0;
      if (dateAssignments >= 2) {
        weight *= 0.5;
      }
      if (dateAssignments >= 3) {
        weight *= 0.25; // Further reduce weight for 3 or more assignments
      }

      if (member.name === 'David' && (position === 'Title 1' || position === 'Title 2')) {
        weight *= 3.5;
      }
      
      if (member.name === 'David' && isSunday && davidSundayCount < 2) {
        weight *= 2;
      }
      
      return weight;
    });

    const totalWeight = weights.reduce((sum, weight) => sum + weight, 0);
    let randomValue = Math.random() * totalWeight;
    
    for (let i = 0; i < members.length; i++) {
      if (randomValue <= weights[i]) {
        return members[i];
      }
      randomValue -= weights[i];
    }
    
    return members[members.length - 1];
  }

  function getTotalAssignments(memberAssignments) {
    return Object.values(memberAssignments).reduce((sum, count) => sum + count, 0);
  }

  function isUnavailable(member, dateString, event) {
    return member.unavailableDates[dateString] && member.unavailableDates[dateString][event];
  }

  function formatDateKey(date) {
    if (!(date instanceof Date)) {
      console.warn('Invalid date object:', date);
      return 'Invalid Date';
    }
    return date.toISOString().split('T')[0];
  }

  return (
    <div className="page-container create-rota-page">
      <h1>CREATE ROTA</h1>
      <div className="algorithm-selection">
        <label>
          <input
            type="radio"
            value="basic"
            checked={selectedAlgorithm === 'basic'}
            onChange={() => setSelectedAlgorithm('basic')}
          />
          Basic Algorithm
        </label>
        <label>
          <input
            type="radio"
            value="weighted"
            checked={selectedAlgorithm === 'weighted'}
            onChange={() => setSelectedAlgorithm('weighted')}
          />
          Weighted Algorithm (with scarcity prioritization)
        </label>
      </div>
      <div className="date-picker-container">
        <label htmlFor="date-picker">Select dates:</label>
        <div className="date-picker-wrapper">
          <DatePicker
            id="date-picker"
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="MMMM d, yyyy"
          />
          <button onClick={addDate}>Add Date</button>
        </div>
      </div>
      {selectedDates.length > 0 && (
        <div className="selected-dates">
          <h2>Selected Dates:</h2>
          <ul>
            {selectedDates.map((date, index) => (
              <li key={index}>
                {formatDateKey(date)}
                <button onClick={() => removeDate(date)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button onClick={generateRota} disabled={selectedDates.length === 0} className="generate-button">
        {location.state?.isRegenerating ? 'Regenerate Rota' : 'Generate Rota'}
      </button>
    </div>
  );
}

export default CreateRotaPage;