import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, onSnapshot, deleteDoc, doc, query, orderBy } from 'firebase/firestore';
import './SchedulesPage.css';

function SchedulesPage() {
  const [schedules, setSchedules] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const rotasRef = collection(db, 'rotas');
    const q = query(rotasRef, orderBy('createdAt', 'desc')); // Sort by createdAt in descending order
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const schedulesArray = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setSchedules(schedulesArray);
    });

    return () => unsubscribe();
  }, []);

  const handleViewRota = (scheduleId) => {
    navigate(`/view-rota/${scheduleId}`);
  };

  const handleDeleteRota = (scheduleId) => {
    setConfirmDelete(scheduleId);
  };

  const confirmDeleteRota = async () => {
    if (confirmDelete) {
      try {
        const rotaRef = doc(db, 'rotas', confirmDelete);
        await deleteDoc(rotaRef);
        console.log('Rota deleted successfully');
      } catch (error) {
        console.error('Error deleting rota:', error);
      }
    }
    setConfirmDelete(null);
  };

  return (
    <div className="page-container schedules-page">
      <h1>Saved Schedules</h1>
      {schedules.length === 0 ? (
        <p>No saved schedules yet.</p>
      ) : (
        <ul className="schedules-list">
          {schedules.map((schedule) => (
            <li key={schedule.id} className="schedule-item">
              <div className="schedule-info">
                <h2>{schedule.name}</h2>
                <span>Created: {new Date(schedule.createdAt).toLocaleString()}</span>
                <span>By: {schedule.createdBy}</span>
              </div>
              <div className="button-group">
                <button onClick={() => handleViewRota(schedule.id)}>View</button>
                <button onClick={() => handleDeleteRota(schedule.id)} className="delete-btn">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {confirmDelete && (
        <div className="confirm-dialog">
          <p>Are you sure you want to delete this rota?</p>
          <div className="confirm-buttons">
            <button onClick={confirmDeleteRota}>Yes, Delete</button>
            <button onClick={() => setConfirmDelete(null)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SchedulesPage;